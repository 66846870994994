import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// import "./TrustpilotReviews.css"
const TrustpilotReviews = () => {
  useEffect(() => {

    const trustpilotScript = document.createElement("script");
    trustpilotScript.type = "text/javascript";
    trustpilotScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    trustpilotScript.async = true;
    document.head.appendChild(trustpilotScript);
  }, []);

  return (
    <div className="trustpilot-container">
      <Helmet>
       
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      
      <div
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="64c3f4851f6068483d2d0012"
        data-style-height="40px"
        data-style-width="100%"
        data-theme="light"
        data-min-review-count="10"
        data-without-reviews-preferred-string-id="1"
        data-style-alignment="center"
      >
        <a className="trust-pilot-link"
          href="https://www.trustpilot.com/review/profusiontechlabs.com"
          target="_blank"
          rel="noreferrer"
         
        >
          Trustpilot
        </a>
      </div>
      
    </div>
  );
};

export default TrustpilotReviews;


