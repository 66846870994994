import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./NavbarStyle.css";
import logo from "../images/logo-blue.png";
import logo2 from "../images/logo-white.png";

function Navbar() { 
    const [logoSrc, setLogoSrc] = useState(logo2);
    const [fix, setFix] = useState(false);
    const [click, setClick] = useState(false);

    // Function to handle navbar behavior on scroll
    const setFixed = () => {
        if (window.scrollY >= 40) {
            setFix(true);
            setLogoSrc(logo);
        } else {
            setFix(false);
            setLogoSrc(logo2);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", setFixed);
        return () => window.removeEventListener("scroll", setFixed);
    }, []);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <>
            <nav className={fix ? "navbar fixed" : "navbar"}>
                <div className="nav-container">
                    <NavLink to="/" className="nav-logo" onClick={closeMobileMenu}>
                        <img src={logoSrc} alt="Logo" />
                    </NavLink>

                    <div className="nav-icon" onClick={handleClick}>
                        <i className={click ? "fa-solid fa-times" : "fas fa-bars"}></i>
                    </div>

                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink
                                to="/"
                                className={({ isActive }) => (isActive ? "nav-links active" : "nav-links")}
                                onClick={closeMobileMenu}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/services"
                                className={({ isActive }) => (isActive ? "nav-links active" : "nav-links")}
                                onClick={closeMobileMenu}
                            >
                                Services
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/contact"
                                className={({ isActive }) => (isActive ? "nav-links active" : "nav-links")}
                                onClick={closeMobileMenu}
                            >
                                Contact
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/career"
                                className={({ isActive }) => (isActive ? "nav-links active" : "nav-links")}
                                onClick={closeMobileMenu}
                            >
                                Career
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
