import React, { useState, useEffect } from "react";
import "./TripStyles.css"

import Aos from "aos";
import "aos/dist/aos.css"
function TripData(props){
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
      setExpanded(!expanded);
    };
    return(
        <div className="t-card" data-aos="fade-up" data-aos-duration="1500">
            <div className="t-image">
                <img src={props.image} alt="" />
            </div>
            <h4>{props.heading}</h4>
            {expanded ? (
        <div>
          <p>{props.text}</p>
          <button onClick={toggleExpanded} className="read-less-button">Read Less</button>
        </div>
      ) : (
        <div>
          <p>{props.text.slice(0, 120) + "..."}</p>
          <button onClick={toggleExpanded} className="read-more-button">Read More...</button>
        </div>
      )}
    </div>
  );
}

export default TripData

// import React from "react";
// import { Link } from "react-router-dom";
// import "./TripStyles.css";

// function TripData(props) {
//   return (
//     <div className="t-card">
//       <div className="t-image">
//         <img src={props.image} alt="" />
//       </div>
//       <h4>{props.heading}</h4>
//       <p>{props.text.slice(0, 100) + "..."}</p>
//       <Link to={props.link} target="_blank" rel="noopener noreferrer">
//         <button className="read-more-button">Read More</button>
//       </Link>
//     </div>
//   );
// }

// export default TripData;

