import "./ServiceStyle.css"
import React, {useEffect} from 'react'
import ServiceData from "./ServiceData";



import Aos from "aos";
import "aos/dist/aos.css"


function ServicePage() {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
    return (
      <div className="service-container" >
        <h1 data-aos="fade-up" data-aos-duration="1500">Explore Our Services</h1>
        <div className="tripcard">
         <ServiceData
            image="https://wallpaperaccess.com/full/4391662.jpg"
            heading="Development"
            text="Software Development: Offer custom software development services using various technologies such as Java,  Salesforce, C#, Python, Ruby,  ReactJS, NodeJS, Microservices, and more."
            text2="Guidewire Implementation: Offer services to help insurance companies implement Guidewire's policy administration, claims management, and billing systems "
            text3="Web Development: Offer website development services using technologies such as HTML, CSS, JavaScript, and PHP."
            text4="DevOps: Offer DevOps services that can help to automate the software development and deployment process, improve collaboration, and increase efficiency."
          />
          <ServiceData
            image="https://images.rawpixel.com/image_1000/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvay1wLTI4OS5qcGc.jpg"
            heading="Cloud Services"
            text="Cloud Development: Offer cloud-based solution development services such as AWS, Azure, Google Cloud, and more."
            text2="Salesforce Implementation: Offer services to help organizations implement Salesforce solutions such as Sales Cloud, Service Cloud, Marketing Cloud, and more. This includes designing and building new Salesforce-based infrastructure and applications, configuring and deploying Salesforce services, and training users."
            text3="Salesforce Consultation: Offer services to help organizations understand the potential of Salesforce and how it can be used to support their business objectives. This includes conducting assessments, providing guidance on Salesforce best practices, and identifying opportunities to improve business processes."

          />
          <ServiceData
            image="https://cdn.pixabay.com/photo/2018/01/25/10/46/businessman-3105873_960_720.jpg"
            heading="Quality Assurance"
            text="Test Automation: Offer services to help organizations automate their testing process, which can save time and improve the accuracy of testing."
            text2="Test Strategy: Offer services to help organizations develop a test strategy, which can improve the quality of their products, services, and systems and reduce the risk of errors."
            text3="Test Metrics: Offer services to help organizations measure and improve the quality of their products, services, and systems using metrics such as test coverage, test efficiency, and defect density."
            text4="Performance Testing: Offer services to help organizations test the performance of their systems, identify bottlenecks, and improve scalability."
            text5="Performance Testing: Offer services to help organizations test the performance of their systems, identify bottlenecks, and improve scalability."
          />
        </div>
      
      </div>
      
    );
  }
  export default ServicePage
