import React from 'react'
import img from "../images/whatsapp_icon.png"
// import {FaWhatsappSquare} from "react-icons/fa"
import "./Whatsapp.css"
const Whatsapp = () => {
  return (
    <div className='whatsapp-container'>
      <a href='https://wa.me/918283043406' target='_blank' rel="noreferrer">
        <img src={img} alt="" className='whatsapp-icon' />
        {/* <FaWhatsappSquare className="whatsapp-icon"/> */}
      </a>
    </div>
  )
}

export default Whatsapp
