// import React, {useEffect} from 'react'
// import { Link } from "react-router-dom";
// import "../Componets/CalendlyPageStyle.css";
// import Aos from "aos";
// import "aos/dist/aos.css"
// const CalendlyPage = () => {
//   useEffect(()=>{
//     Aos.init({duration: 2000})
//   },[])
//   return (
//     <div className="calendly-container" data-aos="fade-up" data-aos-duration="2000">
   
     
        
//           <Link to={"/calendly"} className="hero-btn-2" target="_blank" data-aos="fade-up" data-aos-duration="6000">Schedule A Call</Link>
//         </div>
//   )
// }

// export default CalendlyPage
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Componets/CalendlyPageStyle.css';
import Aos from 'aos';
// import 'aos/dist/aos.css';

const CalendlyPage = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="calendly-container" data-aos="fade-up" data-aos-duration="2000">

    <div ><h1 className="calendly-heading" data-aos="fade-up" data-aos-duration="6000">Effortless Scheduling with PTL Experts</h1></div>
      <Link
        to={"/calendly"}
        className="hero-btn-2"
        target="_blank"
        data-aos="fade-up"
        data-aos-duration="8000"
      >
        Schedule A Call
      </Link>
    </div>
  );
};

export default CalendlyPage;
