// import "./ContactFormStyle.css";

// function ContactForm() {
//   return (
//     <div className="form-container">
//       <div className="form-heading">
//         <h1>WELCOME TO OUR CONTACT US PAGE</h1>
//         <p>
//           We are always happy to hear from our clients, potential clients, and
//           partners. Whether you have a question, a comment, or a project you'd
//           like to discuss, we are here to help
//         </p>
//       </div>
//       <h2 >CONTACT US</h2>
//       <form action="https://formspree.io/f/xpzeylnq" method="Post">
//         <input
//           type="text"
//           name="username"
//           placeholder="Name*"
//           autoComplete="off"
//           required
//         />
//         <input
//           type="email"
//           name="Email"
//           placeholder="Email*"
//           autoComplete="off"
//           required
//         />
//         <textarea
//           name="message"
//           placeholder="Message*"
//           cols="30"
//           rows="6"
//           autoComplete="off"
//           required
//         ></textarea>
//         <div>
//           <button className="">SEND</button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default ContactForm;



import "./ContactFormStyle.css";
import { useForm, ValidationError } from '@formspree/react';
import img from "../images/grd1.jpg"
// import WorkingHoursAndHolidays from "./WorkingHoursAndHolidays";
import Aos from "aos";
import "aos/dist/aos.css"
import React, { useEffect } from "react";
function ContactForm() {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
  const [state, handleSubmit] = useForm("xpzeylnq");
  if (state.succeeded) {
      return <p>Thanks for joining!</p>;
  }
  return (
    <>  
    <div className="contact-heading" >
    <div className="contact-banner">
      <img src={img} alt="" />
      <div className="contact-h1">
      <h1 data-aos="fade-up" data-aos-duration="2000">Contact Us</h1>
      </div>
    </div>
    <h2 data-aos="fade-up" data-aos-duration="2500"> We're Here to Serve You! Get in Touch  for Assistance or Collaborations</h2></div>
    <div className="contact-page" data-aos="fade-up" data-aos-duration="3000">
    
      <div className="address-section"  >
        <h2 data-aos="fade-up" data-aos-duration="2000">Head Office</h2>
     
        <address data-aos="fade-up" data-aos-duration="3000">
        7800 Hamilton Pull Road 200, 
          <br />
          Austin TX 78738,
          USA
          <br />
          Mail Us: contact@profusiontechlabs.com
        </address>
        
      </div>
      <div className="office-location" data-aos="fade-up" data-aos-duration="3000">
    <h2 data-aos="fade-up" data-aos-duration="2000">India Office</h2>
    <address>
      B368, G Block, GM Infinite E-City Town,<br />
      Chandapura, Bangalore, Karnataka - 560099, India
      <br />
          Mail Us: contact@profusiontechlabs.com
    </address>
  </div>
      <div className="contact-form-section" data-aos="fade-up" data-aos-duration="2000">
     
        <form onSubmit ={handleSubmit} method="Post">
          <input
            type="text"
            name="username"
            placeholder="Name*"
            autoComplete="off"
            required
          />
          <input
            type="email"
            name="Email"
            placeholder="Email*"
            autoComplete="off"
            required
            errors={state.errors}
          />
          <textarea
            name="message"
            placeholder="Message*"
            cols="30"
            rows="6"
            autoComplete="off"
            required
          ></textarea>
             <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
          <button type="submit" disabled={state.submitting} data-aos="fade-up" data-aos-duration="1000">SEND</button>
        </form>
      </div>
    </div>
    {/* <WorkingHoursAndHolidays/> */}
   
    </>
  );
}

export default ContactForm;
